<template>
	<Navbar position="fixed" :transparent="transparent" menu-classes="justify-content-center" :marginNav="0" class="nav-height">
		<template>
			<router-link class="nav-link" to="/">
				<div class="d-flex align-items-center justify-content-center">
					<img src="@/assets/image/logo/Icon-nav.svg" height="56px" class="mr-2" style="min-width: 56px" />
				</div>
			</router-link>
		</template>
		<template slot="left-menu">
			<li class="nav-item">
				<router-link class="nav-link" to="/" @click="pushUrl('HOME')">
					<p :class="path == '/' ? 'nav-color p-2' : 'p-2'" class="text-uppercase in-14-semi nav-hover text-nowrap">
						{{ $t("home") }}
					</p>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link class="nav-link" to="/map" target="_blank" @click="pushUrl('MAP')">
					<p :class="path == '/map' ? 'nav-color p-2' : 'p-2'" class="text-uppercase in-14-semi nav-hover text-nowrap">
						{{ $t("web-version") }}
					</p>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link class="nav-link" to="/feature" @click="pushUrl('FEATURE')">
					<p :class="path == '/feature' ? 'nav-color p-2' : 'p-2'" class="text-uppercase in-14-semi nav-hover text-nowrap">
						{{ $t("feature") }}
					</p>
				</router-link>
			</li>
		</template>
		<template slot="navbar-menu">
			<li class="nav-item">
				<router-link class="nav-link" to="/guideline" @click="pushUrl('INTRODUCE')">
					<p :class="path.includes('guideline') ? 'nav-color p-2' : 'p-2'" class="text-uppercase in-14-semi nav-hover text-nowrap">
						{{ $t("user-guideline") }}
					</p>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link class="nav-link" to="/gallery" @click="pushUrl('GALLERY')">
					<p :class="path == '/gallery' ? 'nav-color p-2' : 'p-2'" class="text-uppercase in-14-semi nav-hover text-nowrap">
						{{ $t("libary") }}
					</p>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link class="nav-link" to="/contact" @click="pushUrl('CONTACT')">
					<p :class="path == '/contact' ? 'nav-color p-2' : 'p-2'" class="text-uppercase in-14-semi nav-hover text-nowrap">
						{{ $t("contact") }}
					</p>
				</router-link>
			</li>
			<li class="nav-item">
				<b-nav-item-dropdown style="padding-top: 5px; min-width: 75px" class="in-14-semi" no-caret>
					<template slot="button-content">
						<p class="nav-size text-uppercase" style="color: #029834 !important">
							{{ currentLanguage }}
						</p>
					</template>
					<b-dropdown-item @click="handleChangeLanguage('en')" href="#">EN</b-dropdown-item>
					<b-dropdown-item @click="handleChangeLanguage('vi')" href="#">VI</b-dropdown-item>
				</b-nav-item-dropdown>
			</li>
		</template>
		<template slot="after-menu">
			<li class="nav-item float-right">
				<a class="nav-link" href="https://quanly-cayxanh.nlt-group.com/" @click="pushUrl('GALLERY')">
					<p :class="path == '/gallery' ? 'nav-color p-2' : 'p-2'" class="text-uppercase in-14-semi nav-hover text-nowrap">
						{{ $t("login") }}
					</p>
				</a>
			</li>
		</template>
	</Navbar>
</template>

<script>
import {Navbar} from "@/components";
import {Popover} from "element-ui";
export default {
	name: "main-navbar",
	props: {
		transparent: Boolean,
		colorOnScroll: Number,
	},
	components: {
		Navbar,
		[Popover.name]: Popover,
	},
	watch: {
		menuActive() {},
	},
	created() {
		this.getCurrentLanguage();
		this.$watch(
			() => this.$route.params,
			() => {
				this.fetchData();
			},
			// fetch the data when the view is created and the data is
			// already being observed
			{immediate: true}
		);
	},
	data() {
		return {
			menuActive: localStorage.getItem("currentUrl"),
			path: this.$root._route.fullPath,
			currentLanguage: "",
		};
	},
	methods: {
		pushUrl(url) {
			localStorage.setItem("currentUrl", url);
		},
		fetchData() {
			this.path = this.$root._route.fullPath;
		},
		getCurrentLanguage() {
			if(localStorage.getItem("currentLanguage")){
				let currentLanguage = localStorage.getItem("currentLanguage")
				this.currentLanguage = currentLanguage ==='vi' || currentLanguage ==='en' ? localStorage.getItem("currentLanguage") : "vi";
				this.$i18n.locale = this.currentLanguage;
			}else{
				this.currentLanguage = "vi"
				this.$i18n.locale = this.currentLanguage;
			}
		},
		handleChangeLanguage(value) {
			this.currentLanguage = value;
			this.$i18n.locale = value;
			localStorage.setItem("currentLanguage", value);
			location.reload();
		},
	},
};
</script>

<style scoped>
.nav-height {
	height: 64px;
	/* opacity: 0.9 !important; */
	box-shadow: none !important;
}
.nav-color {
	color: #029834 !important ;
	border-bottom: 2px solid #029834 !important;
}
.nav-size {
	font-size: 14px !important;
}
.nav-hover:hover {
	/* transition: width 0.3s ease; */
	color: #029834 !important ;
	/* border-bottom: 2px solid #c71313; */
}
@keyframes underline {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

.nav-hover {
	position: relative;
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: #029834 !important ; /* Màu chữ */
}

.nav-hover::before {
	content: "";
	position: absolute;
	width: 0;
	height: 2px;
	background-color: #029834;
	bottom: -2px;
	left: 50%;
	transform: translateX(-50%);
	transition: width 0.5s ease, opacity 0.5s ease;
	opacity: 0;
}

.nav-hover:hover::before {
	width: 100%;
	opacity: 1;
}

/* .nav-hover:hover::after {
  width: 100%;
  animation: underline 300ms linear forwards;
}

.nav-hover:not(:hover)::after {
  width: 0%;
} */

/* .nav-hover:not(:hover)::after {
  width: 0%;
} */
</style>
