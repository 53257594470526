<template>
  <footer class="" :data-background-color="backgroundColor">
    <div class="container-fluid backgroud-footer">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-center align-items-center"
          style="background-color: #ffffff; padding: 80px"
        >
          <img src="@/assets/image/logo/Logo-footer.svg" height="160px" />
        </div>
        <div class="col-lg-5 p-80">
          <h3 class="ma-24-semi">Sở Xây dựng Thành phố Cần Thơ</h3>
          <p class="contact">
           {{ $t('address') }}: 25 Ngô Hữu Hạnh, Phường Tân An, Quận Ninh Kiều, TP Cần Thơ
          </p>
          <p class="contact">Hotline:&nbsp;02923820335</p>
          <p class="contact">Email:&nbsp;soxd@cantho.gov.vn</p>
        </div>
        <div class="col-lg-3 p-80 flex-wrap d-flex-nav" style="font-size: 14px">
          <h3 class="company-name" style="visibility: hidden"></h3>
          <p class="text-uppercase mr-3">
            <a href="/" @click="pushUrl('HOME')" class="nav-hover fs-24">
              {{ $t("home") }}
            </a>
          </p>

          <p class="text-uppercase mr-3">
            <a href="/map" @click="pushUrl('MAP')" class="nav-hover fs-24">
              {{ $t("web-version") }}
            </a>
          </p>
          <p class="text-uppercase mr-3">
            <a
              href="/guideline"
              @click="pushUrl('guideline')"
              class="nav-hover fs-24"
            >
              {{ $t("user-guideline") }}
            </a>
          </p>
          <p class="text-uppercase mr-3">
            <a
              href="/feature"
              @click="pushUrl('FEATURE')"
              class="nav-hover fs-24"
            >
              {{ $t("feature") }}
            </a>
          </p>
          <p class="text-uppercase mr-3">
            <a
              href="/gallery"
              @click="pushUrl('GALLERY')"
              class="nav-hover fs-24"
            >
              {{ $t("libary") }}
            </a>
          </p>
          <p class="text-uppercase mr-3">
            <a
              href="/contact"
              @click="pushUrl('CONTACT')"
              class="nav-hover fs-24"
            >
              {{ $t("contact") }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  methods: {
    pushUrl(url) {
      localStorage.setItem("currentUrl", url);
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 1024px) {
  .p-80 {
    padding: 80px !important;
  }
  .d-flex-nav{
	 display: block !important;
}
}
.p-80 {
  padding: 16px;
}
.d-flex-nav{
	 display: flex;
}
.fs-24 {
  font-size: 14px;
}
.contact {
  font-size: 14px;
}

@keyframes underline {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.nav-hover {
  position: relative;
  display: inline-block;
  /* padding: 10px 20px; */
  /* font-size: 16px; */
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav-hover::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #fafafa;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.5s ease, opacity 0.5s ease;
  opacity: 0;
}

.nav-hover:hover::before {
  width: 100%;
  opacity: 1;
}

.company-name {
  font-size: 24px;
}
.backgroud-footer {
  background-image: url("/assets/image/bg/BG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
