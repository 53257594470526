<template>
  <div>
    <div class="page-header clear-filter" style="min-height: 640px">
      <parallax
        class="page-header-image"
        style="background-image: url('/assets/image/bg/bg_1.png')"
      >
      </parallax>
      <div
        class="container px-0 d-flex justify-content-center align-items-center h-100 mt-4"
      >
        <div class="title-content">
          <h3 class="ma-56-semi m-0">{{ $t('gallery') }}</h3>
          <p class="in-20-re">
            {{ $t('gallery-description') }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="custom-container"
      style="margin-top: 120px; margin-bottom: 120px"
    >
      <div class="row">
        <div
          v-for="(e, index) in columnImage"
          :key="index"
          class="col-lg-4 mb-4"
        >
          <div
            v-for="(item, i) in e"
            :key="i"
            class="image-frame"
          >
            <img width="100%" :src="item.link" @click="showWallpaper(e, i)">
          </div>
        </div>
      </div>
    </div>
    <div id="fullscreen-wallpaper-light">
      <div class="button-close-wallpaper m-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          class="btn-close"
          @click="hideWallpaper"
        >
          <path d="M0 40L40 0" stroke="white" stroke-linecap="round" />
          <path d="M0 0L40 40" stroke="white" stroke-linecap="round" />
        </svg>
      </div>
      <div class="image-wallpaper" @keydown="closeSlide($event)">
        <BCarousel :list-image="listImage" :slideindex="slide" />
      </div>
    </div>
  </div>
</template>

<script>
import BCarousel from "@/components/BCarouselComponent";
export default {
  components: {
    BCarousel,
  },
  data() {
    return {
      Images: [
        { link: "/assets/image/bg/bg_14.png" },
        { link: "/assets/image/bg/bg_22.jpg" },
        { link: "/assets/image/bg/img_5.png" },
        { link: "/assets/image/bg/bg_24.jpg" },
        { link: "/assets/image/bg/img_2.jpg" },
        { link: "/assets/image/bg/img_3.jpg" },
        { link: "/assets/image/bg/img_4.png" },
        { link: "/assets/image/bg/bg_24.jpg" },
        { link: "/assets/image/bg/bg_22.png" },
        { link: "/assets/image/bg/img_1.jpg" },
        { link: "/assets/image/bg/bg_23.jpg" },
        { link: "/assets/image/bg/bg_24.jpg" },
        { link: "/assets/image/bg/bg_15.png" },
        { link: "/assets/image/bg/img_1.jpg" },
        { link: "/assets/image/bg/bg_22.jpg" },
      ],
      listImage: [],
      slide: 0,
    };
  },
  computed: {
    columnImage() {
      const originalArray = this.Images;
      const numberOfArrays = 3;
      const chunkSize = Math.ceil(originalArray.length / numberOfArrays);
      const resultArray = [];
      for (let i = 0; i < originalArray.length; i += chunkSize) {
        const chunk = originalArray.slice(i, i + chunkSize);
        resultArray.push(chunk);
      }
      return resultArray;
    },
  },
  methods: {
    hideWallpaper() {
      this.listImage = null;
      let targetDiv = document.getElementById("fullscreen-wallpaper-light");
      targetDiv.style.display = "none";
    },
    closeSlide(event) {
      let x = event.keyCode;
      if (x == 27) {
        alert("You pressed the Escape key!");
      }
    },
    showWallpaper(list, key) {
      let result = [];
      this.listImage = null;
      list.map((item, index) => {
        let obj = {
          ...item,
          ID: index,
          Image: item.link, //img
        };
        result.push(obj);
      });
      // result.forEach((item, index) => {
      //     if (index == key) {
      //         const tmp = { ...result[0] };
      //         result[0] = result[index];
      //         result[index] = tmp;
      //     }
      // });
      this.slide = key;
      this.listImage = result;
      let targetDiv = document.getElementById("fullscreen-wallpaper-light");
      if (
        targetDiv.style.display === "none" ||
        targetDiv.style.display === ""
      ) {
        targetDiv.style.display = "block";
      }
    },
  },
};
</script>

<style scoped>
.image-frame {
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden !important;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.title-content {
  margin-top: 120px;
}
/* .btn-close {
  background: none;
  border: 1px solid red;
  color: red;
} */
.btn-close:hover path {
  stroke: #029834;
}
#fullscreen-wallpaper-light {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.95);
  cursor: pointer;
}

#fullscreen-wallpaper-light .image-wallpaper {
  width: 100%;
  height: 100%;
}

#fullscreen-wallpaper-light .button-close-wallpaper {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

#fullscreen-wallpaper-light .button-close-wallpaper .btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0.6rem;
}

#fullscreen-wallpaper-light .button-close-wallpaper .btn i {
  font-size: 20px;
}
</style>
